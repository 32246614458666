import React, { useState, useEffect } from 'react';
import Tree from 'react-d3-tree';
import axios from 'axios';
import treeObject from './TreeData.json';
import createTreeConfig from './TreeConfig.tsx';
import './TreeStyling.css';
import TreeModal from './TreeModal';
import localImage from '../assets/packing-line-factory-process-optimisation.jpg';

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const ProfitTree = () => {
  const [treeContainerDimensions, setTreeContainerDimensions] = useState({
    width: '1230px',
    height: '770px',
  });

  const [selectedNode, setSelectedNode] = useState(null);
  const [savedItems, setSavedItems] = useState([]);
  const [modal, setModal] = useState(false)
  const [ideaDetails, setIdeaDetails] = useState([
    {
      "idea_header": "Great ideas belong here",
      "idea_details": "Loading...",
      "idea_case": "Loading...",
      "idea_company": "Loading...",
      "idea_source": "Loading...",
      "idea_image": localImage
    }

  ])

  //MODAL TOGGLE
  const toggleModal = () => {
    setModal(!modal)
    document.body.classList.toggle('active-modal');
    console.log(`Modal state:${modal}`) //TO REMOVE
  }

  const TreeIdeaDetailPull = (ID) => {
    const getRequest = `${BACKEND_BASE_URL}/api/detailsfetch/${ID}`
    axios.get(getRequest)
      .then((response) => {
        setIdeaDetails(response.data);
      }
      )
      .catch((error) => {
        console.log(error);
      })
  }

  const handleNodeClick = (nodeData) => {
    if (nodeData && !nodeData.data.children) {
      toggleModal();
      setSelectedNode(nodeData);
      TreeIdeaDetailPull(nodeData.data.ideaID)
      console.log(`IDEAID ${nodeData.data.ideaID}`)//REMOVE
    }

    //TO REMOVE AT BUILD
    console.log('Selected Node Data:', nodeData); // Log the selected node data
    console.log('Selected Node Data Name:', nodeData.data.name); // Log the selected node data
    console.log(`Modal state:${modal}`)
  };

  // Function to save the updated savedItems array to session storage
  const saveItemsToSessionStorage = (items) => {
    try {
      sessionStorage.setItem('savedItems', JSON.stringify(items));
    } catch (error) {
      console.error('Error saving to session storage', error);
    }
  };

  // Function to load saved items from session storage (on component mount)
  const loadItemsFromSessionStorage = () => {
    try {
      const storedItems = sessionStorage.getItem('savedItems');
      if (storedItems) {
        setSavedItems(JSON.parse(storedItems));
      }
    } catch (error) {
      console.error('Error loading from session storage', error);
    }
  };

  // Use the useEffect hook to load saved items from session storage on component mount
  useEffect(() => {
    loadItemsFromSessionStorage();
  }, []); // The empty dependency array ensures this effect runs once, on component mount

  const handleAddToSavedItems = () => {
    if (selectedNode.data.name) {
      const updatedItems = [...savedItems, selectedNode.data.name];
      setSavedItems(updatedItems);

      // Save the updated items to session storage
      saveItemsToSessionStorage(updatedItems);
    }
  };

  useEffect(() => {
    const updateDimensions = () => {
      const treeContainer = document.getElementById('treeWrapper');
      if (treeContainer) {
        const dimensions = treeContainer.getBoundingClientRect();
        setTreeContainerDimensions({
          width: dimensions.width,
          height: dimensions.height,
        });
      }
    };

    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const treeConfig = createTreeConfig(treeContainerDimensions)

  return (
    <div id='content-to-cover'>
      <div className='treeTool'>
        <div id='treeWrapper'>
          <Tree
            data={treeObject}
            onNodeClick={handleNodeClick}
            rootNodeClassName='node__root'
            branchNodeClassName='node__branch'
            leafNodeClassName='node__leaf'
            {...treeConfig}
          />
        </div>


        {selectedNode && !selectedNode.data.children && modal && (
          <TreeModal
            toggleModal={toggleModal}
            handleAddToSavedItems={handleAddToSavedItems}
            selectedNode={selectedNode}
            ideaDetails={ideaDetails}
          />
        )}
      </div>
    </div>
  );
};

export default ProfitTree;
