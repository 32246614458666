import React from 'react'
import './ToolIntro.css'
import { FaLightbulb, FaIndustry, FaSourcetree } from "react-icons/fa";
import CountToTarget from '../functions/CountToTarget';

const ToolIntro3 = () => {
    return (
        // <div className='tool-text-wide'>
        <div className='tool-intro-3'>
            <ul className='tool-text-icon-grid'>
                <li><h2><CountToTarget targetTimeInSeconds={4} targetNumber={789} /></h2>
                </li>
                <li className='tool-text-icons'><FaLightbulb />
                </li>
                <li><h4>Ideas</h4>
                </li>
            </ul>
            <span className="seperation-bar"></span>
            <ul className='tool-text-icon-grid'>
            <li><h2><CountToTarget targetTimeInSeconds={2} targetNumber={2} /></h2>
                </li>
                <li className='tool-text-icons'><FaIndustry />
                </li>
                <li><h4>Industries</h4>
                </li>
            </ul>
            <span className="seperation-bar"></span>
            <ul className='tool-text-icon-grid'>
            <li><h2><CountToTarget targetTimeInSeconds={3} targetNumber={125} /></h2>
                </li>
                <li className='tool-text-icons'><FaSourcetree />
                </li>
                <li><h4>Sources</h4>
                </li>
            </ul>

        </div>
    )
}

export default ToolIntro3