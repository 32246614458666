import ToolIntro1 from "../components/ToolIntro1";
import ToolIntro3 from "../components/ToolIntro3";
import ToolIntro4 from "../components/ToolIntro4";

import React from 'react'

const Homepage = () => {
  return (
    <div>
        <ToolIntro1 />
        <ToolIntro4 />
        <ToolIntro3 />
    </div>
  )
}

export default Homepage