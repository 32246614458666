import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/Icon.svg'
import './Navbar.css';

const Navbar = () => {
    // Add a state variable to manage the active/inactive state of the hamburger
    const [isActive, setIsActive] = useState(false);
    const navRef = useRef(null);

    // Function to toggle the active state of the hamburger
    const toggleHamburger = () => {
        setIsActive(!isActive);
    };

       // Add an event listener to close the hamburger menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (navRef.current && !navRef.current.contains(e.target)) {
                setIsActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav ref={navRef} className='navbar'>
            <div className='nav-logoblock'>
            <Logo />
            <Link to="/" className='nav-brandname'>The Webster Code</Link>
            </div>


            <ul className={`nav-menu ${isActive ? 'active' : ''}`}>
                <li className='nav-item'>
                    <Link to="/" className='nav-link'>Home</Link>
                </li>

                <li className='nav-item'>
                    <Link to="/blog" className='nav-link'>Blog</Link>
                </li>

                <li className='nav-item'>
                    <Link to="/tool" className='nav-link'>Tool</Link>
                </li>

                <li className='nav-item'>
                    <Link to="/treeview" className='nav-link'>Tree View</Link>
                </li>

                <li className='nav-item'>
                    <Link to="/treeedit" className='nav-link'>Tree Edit</Link>
                </li>

                <li className='nav-item'>
                    <Link to="/benchmarking" className='nav-link'>Benchmarking</Link>
                </li>
            </ul>

            {/* Add the click event to toggle the hamburger */}
            <div className={`hamburger ${isActive ? 'active' : ''}`} onClick={toggleHamburger}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
        </nav>
    );
};

export default Navbar;
