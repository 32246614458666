import { Route, Routes } from "react-router-dom"
import Homepage from "./pages/Homepage";
import BlogSinglePage from "./pages/BlogSinglePage";
import BlogSummaryPage from "./pages/BlogSummaryPage";
import Navbar from "./components/Navbar"
// import SubscribeForm from "./components (redundant)/SubscribeForm";
import Footer from "./components/Footer";
import Tool from "./components/Tool";
import TreeViewPage from "./pages/TreeViewPage";
import TreeEditPage from "./pages/TreeEditPage";
import NotFoundPage from "./pages/NotFoundPageTemp";

export function App() {
  return (
    <>
    <Navbar />
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/tool" element={<Tool />} />
      <Route path="/blog" element={<BlogSummaryPage/>} />
      <Route path="/blog/:id" element={<BlogSinglePage/>} />
      <Route path="/treeview" element={<TreeViewPage />} />
      <Route path="/treeedit" element={<TreeEditPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    {/* <SubscribeForm /> */}
    <Footer />
    </>
  )
}

export default App