import React, { useState, useEffect } from 'react';
import './Tool.css';
import Axios from "axios"

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_URL

function Tool() {

    //PERFORM STATE MANAGEMENT ON THE LOADING VARIABLES
    const [currentDisplayLevel, setCurrentDisplayLevel] = useState(1)
    const [treeData, setTreeData] = useState(["1.1", "1.2"])
    const [refreshTreeData, setRefreshTreeData] = useState([]); // The array received from the server
    const [ideaData, setIdeaData] = useState(["Idea","Idea"])
    const [ideaLevelFlag, setIdeaLevelFlag] = useState("level_3")
    const [modal, setModal] = useState(false)

    //BUTTON INCREMENT CALL
    const LevelIncrement = () => {
        setCurrentDisplayLevel(currentDisplayLevel + 1)
    }

    //BUTTON DECREMENT CALL
    const LevelDecrement = () => {
        setCurrentDisplayLevel(currentDisplayLevel - 1)
    }

    //MODAL TOGGLE
    const toggleModal = () => {
        setModal(!modal)
        document.body.classList.toggle('active-modal');
    }

    //LEVEL UP VALUE CALL
    const LevelUpCall = (buttonValue) => {
        const getRequest = `${BACKEND_BASE_URL}/api/get/levelup/${currentDisplayLevel}/${buttonValue}`
        Axios.get(getRequest)
            .then((response) => {
                setRefreshTreeData(response.data);
                setIdeaLevelFlag(response.data[0]["idea_level"])
                LevelIncrement();
            })
    }

    //BACK BUTTON VALUE CALL
    const LevelDownCall = () => {
        const getRequest = `${BACKEND_BASE_URL}/api/get/leveldown/${currentDisplayLevel}/${(treeData[0])}`
        Axios.get(getRequest)
            .then((response) => {
                setRefreshTreeData(response.data);
                setIdeaLevelFlag(response.data[0].idea_level)
                LevelDecrement();
            })
    }

    //UPDATE TREE DATA BY EXTRACTING THE FIRST VALUE FROM EACH OBJECT
    useEffect(() => {
        if (refreshTreeData.length > 0) {
            const keys = Object.keys(refreshTreeData[0]); // Get the keys from the first object
            if (keys.length >= 1) {
                const newData = refreshTreeData.map(item => item[keys[0]]); // Extract the first value from each object
                setTreeData(newData);
            }
        }
    }, [refreshTreeData]);

    //IDEA DATA CALL
    const IdeaDataCall = (buttonValue) => {
        toggleModal();
        const getRequest = `${BACKEND_BASE_URL}/api/get/ideadata/${ideaLevelFlag}/${buttonValue}`
        Axios.get(getRequest)
            .then((response) => {
                setIdeaData(response.data);
            })
    }

    //STANDARD TREE BUTTONS
    const TreeButtons = () => {
        return (
            <>
                <div>
                    {treeData.map((val) => (
                        <button className="tree-btn" onClick={() => LevelUpCall(val)}>{val}</button>
                    ))}
                </div>
            </>
        );
    }

    //IDEA BUTTONS
    const IdeaButtons = () => {
        return (
            <>
                <div className="modal-btn">
                    {treeData.map((val) => (
                        <button onClick={() => IdeaDataCall(val)}>{val}</button>
                    ))}
                </div>

                {modal && (
                    <div className="modal">
                        <div onClick={toggleModal}
                            className="overlay"></div>
                        <div className="modal-content">
                            <div>
                                {ideaData[0]["idea_company"] && (<p>Company: {ideaData[0]["idea_company"]}</p>)}
                                {ideaData[0]["idea_value"] && (<p>Idea Value: {ideaData[0]["idea_value"]}</p>)}
                            </div>
                            <button
                                className='close-modal'
                                onClick={toggleModal}
                            > CLOSE </button>
                        </div>
                    </div>
                )}
            </>
        );
    }

    //BUTTONS
    const BackButton = () => {
        const backBtnContent = `< Click Back`
        return (
            <>
                <button className='back-btn' onClick={() => LevelDownCall()}>{backBtnContent}</button>
            </>
        );
    }

    return (
        <div className='tool-window'>
            <h3>Select your interest area:</h3>
            <div className='btn-window'>
            {currentDisplayLevel > 1 ? <BackButton />:<></>}
            {currentDisplayLevel === ideaLevelFlag ? <IdeaButtons /> : <TreeButtons />}

            <p>1st Value of Current Data: {(treeData[0])}</p>
            <p>All Current Data: {treeData}</p>
            <p>Current Display Level: {currentDisplayLevel}</p>
            <p>Idea Level Flag: {ideaLevelFlag}</p>
            </div>
        </div>
    )
}

export default Tool;