import React, { useState, useEffect } from 'react';

const easeOutQuad = (t) => {
  return t * (2 - t);
};

const CountToTarget = ({ targetTimeInSeconds, targetNumber }) => {
  const targetTime = targetTimeInSeconds * 1000; // Convert targetTimeInSeconds to milliseconds
  const [count, setCount] = useState(1);

  useEffect(() => {
    const startTime = performance.now();
    const updateCount = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      if (elapsedTime < targetTime) {
        const progress = (elapsedTime / targetTime) * targetNumber;
        setCount(Math.floor(easeOutQuad(progress / targetNumber) * targetNumber));
        requestAnimationFrame(updateCount);
      } else {
        setCount(targetNumber);
      }
    };
    requestAnimationFrame(updateCount);
  }, [targetTime, targetNumber]);

  return <div>{count}</div>;
};

export default CountToTarget;
