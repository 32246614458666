import React from 'react';
import { Link } from 'react-router-dom';

const BlogSummary = ({id, title, image, altImage, subheader }) => {
  return (
    
    <div className='blogcarousel__suggestion'>
      <Link className='blogcarousel__linkwrapper' to={`/blog/${id}`}>
      <h4 className='blogcarousel__subheader'>{title}</h4>
      <img className='blogcarousel__image blogcarousel__image--2' src={image} alt={altImage} />
      <p className='blogcarousel__subsubheader'>{subheader}</p>
      </Link>
    </div>
  );
};

export default BlogSummary;