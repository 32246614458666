import React from 'react';
import './Footer.css';
import './ToolIntro.css'

const Footer = () => {
  return (
    <div>
        <h5 className='footer'>Copyright 2023</h5>
    </div>
  )
}

export default Footer