import React from 'react'
import { Link } from 'react-router-dom';
import '../components/NavigationButton.css';
import { FaArrowRight} from "react-icons/fa";

const NavigationButton = ({ to, label }) => {
  return (
    <Link to={to}>
      <button className='orange-button'>{label}<FaArrowRight /></button>
    </Link>
  );
};

export default NavigationButton