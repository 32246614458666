import React from 'react'
import '../components/Blog.css'
import localImage from '../assets/packing-line-factory-process-optimisation.jpg';

const BlogSidebar = () => {
  return (
    <section className='blogsinglepage__side'>
    <div className='blogside'>
      <div className='blogtakeout'>
        <h2 className='blogtakeout__header'>Takeaway ideas</h2>
        <div className='blogtakeout__idea blogtakeout__idea--1'>
          <h3 className='blogtakeout__subheader'>Do x to improve profit in Y way</h3>
          <img className='blogtakeout__image blogtakeout__image--1' src={localImage} alt='takeout'></img>
        </div>
        <div className='blogtakeout__idea blogtakeout__idea--2'>
          <h3 className='blogtakeout__subheader'>Do x to improve profit in Y way</h3>
          <img className='blogtakeout__image blogtakeout__image--1' src={localImage} alt='takeout'></img>
        </div>
        <button className='blogside__btn'>Click for more</button>
      </div>
    </div>
    </section>
  )
}

export default BlogSidebar

