import React from 'react'
import '../components/Blog.css'
import BlogSummary from './BlogSummary';
import { blogDetailsArray } from './BlogDetailsArray';


const suggestionsData = blogDetailsArray
const BlogSummaryCarousel = () => {
    
  //could insert useState with fallback array here
  
  return (
        <div className='blogcarousel'>
            <h3 className='blogcarousel__header'>Blog Section</h3>
            <div className='blogcarousel__section'>
      {suggestionsData.map((suggestion) => (
        <BlogSummary key={suggestion.id} {...suggestion} />
      ))}
    </div>
    </div>
    )}
export default BlogSummaryCarousel