
import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../components/Blog.css'
import { blogDetailsArray } from './BlogDetailsArray';
import { FaArrowLeft } from 'react-icons/fa';

const BlogRelated = () => {

    const [firstRelated, setFirstRelated] = useState(1);
    const [secondRelated, setSecondRelated] = useState(2);
    const [firstRelatedDetails, setFirstRelatedDetails] = useState([]);
    const [secondRelatedDetails, setSecondRelatedDetails] = useState([]);

    // 1. Useparams to get the value of current blog
    const blogParams = useParams();
    const currentId = parseInt(blogParams.id, 10)

    // 2. Look up the ID of first and second recommnded blogs in the object and store in variables
    useEffect(() => {
        const blogToInterrogate = blogDetailsArray.filter(blog => blog.id === currentId);
        setFirstRelated(blogToInterrogate[0].relatedId1)
        setSecondRelated(blogToInterrogate[0].relatedId2)
        const blogToPresentFirst = blogDetailsArray.filter(blog => blog.id === firstRelated);
        setFirstRelatedDetails(blogToPresentFirst[0])
        console.log(firstRelatedDetails);
        const blogToPresentSecond = blogDetailsArray.filter(blog => blog.id === secondRelated);
        setSecondRelatedDetails(blogToPresentSecond[0])
        console.log(secondRelatedDetails);

    }, [currentId, firstRelated, firstRelatedDetails, secondRelated, secondRelatedDetails]);


    return (
       <section className='blogsinglepage__related'>
       <div className='relatedblog'>
            <h3 className='relatedblog__header'>Related blogs</h3>
            <div className='relatedblog__section'>
                <div className='relatedblog__suggestion'>
                    <Link className='relatedblog__linkwrapper' to={`/blog/${firstRelatedDetails.id}`}>
                        <h4 className='relatedblog__subheader'>{firstRelatedDetails.title}</h4>
                        <img className='relatedblog__image relatedblog__image--1' src={firstRelatedDetails.image} alt={firstRelatedDetails.imageAlt}></img>
                        <p className='relatedblog__subsubheader'>{firstRelatedDetails.subheader}</p>
                    </Link>
                </div>

                <div className='relatedblog__suggestion'>
                    <Link className='relatedblog__linkwrapper' to={`/blog/${firstRelatedDetails.id}`}>
                        <h4 className='relatedblog__subheader'>{secondRelatedDetails.title}</h4>
                        <img className='relatedblog__image  relatedblog__image--2' src={secondRelatedDetails.image} alt={secondRelatedDetails.imageAlt}></img>
                        <p className='relatedblog__subsubheader'>{secondRelatedDetails.subheader}</p>
                    </Link>
                </div>
            </div>
            <Link to="/blog" className='blogmain__linkwrapper'><h5 className='blogmain__backlink'><FaArrowLeft />   To Blog Area</h5></Link>
        </div>
        </section>
    )
}

export default BlogRelated