import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../components/Blog.css';
import { blogDetailsArray } from './BlogDetailsArray';
import { FaArrowLeft } from 'react-icons/fa';

const fallbackBlogDetails = [
    {
        "id": 1,
        "title": "Blog Title 1",
        "author": "John Doe",
        "dateUpdated": "2023-01-01",
        "subheader": "Lorem ipsum 1",
        "image": "https://www.w3schools.com/images/mylearning.png",
        "imageAlt": "Image Alt Text 1",
        "imageDescription": "Image Description 1",
        "body": "Lorem ipsum body content 1"
    }
]

const BlogBody = () => {

    const [blogDetails, setBlogDetails] = useState(fallbackBlogDetails);
    const blogParams = useParams();
    const targetId = parseInt(blogParams.id, 10)
    console.log(targetId)

    useEffect(() => {
        const blogToDisplay = blogDetailsArray.filter(blog => blog.id === targetId);
        setBlogDetails(blogToDisplay[0]);
    }, [targetId]);

    return (
        <section className='blogsinglepage__blog'>
            <Link className='blogmain__linkwrapper' to="/blog"><h5 className='blogmain__backlink'><FaArrowLeft />   To Blog Area</h5></Link>

            <div className='blogbody'>
                <h2 className='blogbody__header'>{blogDetails.title}</h2>
                <h5 className='blogbody__citation'>{`${blogDetails.author} | Date Updated: ${blogDetails.dateUpdated}`}</h5>
                <h3 className='blogbody__subheader'>{blogDetails.subheader}</h3>
                <div className='blogcover'>
                    <img className='blogcover__image blogcover__image--main' src={blogDetails.image} alt={blogDetails.imageAlt} />
                    <h5 className='blogcover__caption'><i>{blogDetails.imageDescription}</i></h5>
                </div>
                <p className='blogbody__body'>{blogDetails.body}</p>
            </div>
        </section>

    );
};

export default BlogBody;
