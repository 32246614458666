import React from 'react'
import '../components/NotFound.css'

const NotFoundPage = () => {
  return (
    <div className='notfound'>
      <h1 className='notfound__header'>
      404: Page Not Found
      </h1>
      <h3 className='notfound__subheader'>
      ...but great ideas available here:
      </h3>



    </div>
  )
}

export default NotFoundPage