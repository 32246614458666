import React from 'react';
import TreeTool from '../treeComponents/Tree'

const TreeViewPage = () => {
  return (
      <>
      <TreeTool/>
      </>
  )
}

export default TreeViewPage