export const blogDetailsArray = [
  {
    "id": 1,
    "title": "Blog Title 1",
    "author": "John Doe",
    "dateUpdated": "2023-01-01",
    "subheader": "Lorem ipsum 1",
    "image": "https://www.w3schools.com/images/mylearning.png",
    "imageAlt": "Image Alt Text 1",
    "imageDescription": "Image Description 1",
    "body": "Lorem ipsum body content 1",
    "relatedId1": 2,
    "relatedId2": 3,
  },
  {
    "id": 2,
    "title": "Blog Title 2",
    "author": "Jane Doe",
    "dateUpdated": "2023-01-02",
    "subheader": "Lorem ipsum 2",
    "image": "https://www.w3schools.com/images/mylearning.png",
    "imageAlt": "Image Alt Text 2",
    "imageDescription": "Image Description 2",
    "body": "Lorem ipsum body content 2",
    "relatedId1": 1,
    "relatedId2": 3,
  },
  {
    "id": 3,
    "title": "Blog Title 3",
    "author": "Jane Doe",
    "dateUpdated": "2023-01-02",
    "subheader": "Lorem ipsum 3",
    "image": "https://www.w3schools.com/images/mylearning.png",
    "imageAlt": "Image Alt Text 3",
    "imageDescription": "Image Description 3",
    "body": "Lorem ipsum body content 3",
    "relatedId1": 1,
    "relatedId2": 4,
  },
  {
    "id": 4,
    "title": "Blog Title 4",
    "author": "Jane Doe",
    "dateUpdated": "2023-01-02",
    "subheader": "Lorem ipsum 4",
    "image": "https://www.w3schools.com/images/mylearning.png",
    "imageAlt": "Image Alt Text 4",
    "imageDescription": "Image Description 4",
    "body": "Lorem ipsum body content 4",
    "relatedId1": 2,
    "relatedId2": 3,
  },
];