import React from 'react'
import TreeTool from '../treeComponents/Tree'
import EditPanel from '../treeComponents/TreeEditPanel'

const TreeEditPage = () => {
  return (
    <div className='red-border'>
      <>
      <EditPanel />
      <TreeTool />
      </>
      </div>
  )
}

export default TreeEditPage