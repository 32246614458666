import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faCircleXmark} from '@fortawesome/free-solid-svg-icons'


const TreeModal = ({ toggleModal, handleAddToSavedItems, selectedNode, ideaDetails }) => {
    return (
        <div className="modal">
            
            <div
                className="modal__underlay"></div>
            
            <div className='card'>
            <div className='card__interaction'>
                            <button
                                className='btn card__save'
                                onClick={handleAddToSavedItems}
                            > <FontAwesomeIcon icon={faHeart} />
                            </button>
                            <button
                                className='btn card__close'
                                onClick={toggleModal}> 
                               <FontAwesomeIcon icon={faCircleXmark} /> 
                            </button>
                        </div>
            
            <div onClick={toggleModal} className="card__grid">
               
                <div className="card__box box1">
                    <img src={ideaDetails[0].idea_image} alt={ideaDetails[0].idea_image}/>

                </div>

                <div className="card__box box2">
                    <div>
                        <h4 className='card__header'>{ideaDetails[0].idea_header}</h4>
                        <div className='card__hierarchies'>
                        </div>
                    </div>
                </div>

                <div className="card__box box3">
                <p className='card__details'>{ideaDetails[0].idea_company !== null ? <><b>Company:</b> {ideaDetails[0].idea_company}</> : ''}</p>
                <p className='card__details'>{ideaDetails[0].idea_case !== null ? <><b>Case Study:</b> {ideaDetails[0].idea_case}</> : ''}</p>
                <p className='card__details'><b>Source: </b>{ideaDetails[0].idea_source} </p> 
                </div>
               
                <div className="card__box box4">
                <p className='card__details'><b>Details:</b></p> 
                <p className='card__details'>{ideaDetails[0].idea_details}</p>
                </div>

                </div>
            </div>
        </div>
    )
}

// {selectedNode.data.name}
// {selectedNode.data.ideaID}

export default TreeModal


