import React from 'react'
import './ToolIntro.css'

const ToolIntro1 = () => {
    return (
        <div className='tool-intro-1'>
            <div className='tool-text-wide'>
                <h1>Make <span className='circle-highlight'>Money</span> in Consumer Goods</h1>
                <br></br>
                <p>Top Tier Insights. No Consultants Needed</p>
                <p>Hundreds of Ideas to Improve Profit.</p>
            </div>
            <div className='tool-text-narrow'>
                    <h2>Make</h2>
                    <h2 className='circle-highlight'>Money</h2>
                    <h2>In Consumer Goods</h2>
                    <br></br>
                    <p>Top Tier Insight.</p>
                    <p>No Consultants Needed.</p>
                    <p>Hundreds of Ideas to Improve Profit</p>
            </div>
        </div>
            )
}

            export default ToolIntro1