import React from 'react'
import BlogSummaryCarousel from '../components/BlogSummaryCarousel'

const BlogSummaryPage = () => {
  return (
    <div>
  <BlogSummaryCarousel />
  </div>
    )
}

export default BlogSummaryPage