import React from 'react'

import BlogBody from '../components/BlogBody'
import BlogRelated from '../components/BlogRelated'
import BlogSidebar from '../components/BlogSidebar'

const BlogSinglePage = () => {
  return (
<div className='blogsinglepage'>
<BlogBody />
<BlogRelated />
<BlogSidebar />
</div>
  )
}

export default BlogSinglePage

