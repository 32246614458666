// treeConfig.js
const createTreeConfig = (treeContainerDimensions) => {
  return {
    orientation: 'horizontal',
    initialDepth: 1,
    nodeSize:
      parseInt(treeContainerDimensions.width) > 492
        ? { x: 150, y: 90 }
        : { x: 100, y: 60 },

    separation:
      parseInt(treeContainerDimensions.width) > 492
      ? { siblings: 1, nonSiblings: 1 }
      : { siblings: 2, nonSiblings: 2 },

    translate: {
      x: treeContainerDimensions.width / 2,
      y: treeContainerDimensions.height / 2,
    },
    shouldCollapseNeighborNodes: false,
    enableLegacyTransitions: true,
    transitionDuration: 300,
    centeringTransitionDuration: 300,
    pathFunc: 'elbow',
    dimensions: treeContainerDimensions,
  };
};

export default createTreeConfig;
