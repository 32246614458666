import React from 'react'
import './ToolIntro.css'
import NavigationButton from '../functions/NavigationButton'
import ToolImage from '../assets/tool-image.png'

const ToolIntro4 = () => {
    return (
    
        <div className='tool-intro-4'>
       
                <img className='tool-screenshot' src={ToolImage} alt="Profit Tool" height="200" />

            <div className='tool-text-icon-grid'>
            <ul>
                <li><h3>Idea Group<span style={{ color: '#F4BF2F' }}> + </span></h3></li>
                <li><h4>Item Detail 1</h4></li>
                <li><h4>Item Detail 2</h4></li>
                <li><h4>Item Detail 3</h4></li>
            </ul>
            <br/>
            <NavigationButton to="/tool" label="Find Ideas Now" />
            </div>
        
        </div >
      
    )
}

export default ToolIntro4