import React, { useState } from 'react';
const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_URL

const EditPanel = () => {
  const [formData, setFormData] = useState({
    oldParentNode: '',
    newChildNode: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `${BACKEND_BASE_URL}/api/treedata/${formData.oldParentNode}/${formData.newChildNode}`,
        {
          method: 'POST',
        }
      );
      if (response.ok) {
        // Handle a successful response, such as updating the UI or showing a success message.
        console.log('Post request succeeded');
      } else {
        console.error('Failed to update tree data');
      }
    } catch (error) {
      console.error('Error updating tree data', error);
    }
  };

  return (
    <div>
      <h3>Edit Panel</h3>
      <div>
        <label>Old Parent Node:</label>
        <input
          type="text"
          name="oldParentNode"
          value={formData.oldParentNode}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>New Child Node:</label>
        <input
          type="text"
          name="newChildNode"
          value={formData.newChildNode}
          onChange={handleChange}
        />
      </div>
      <div>
        <button type="button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default EditPanel;
